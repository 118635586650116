import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection, LargeSection } from "../components/containers"
import { H1, H2, H4 } from "../components/typography"
import { FlDivider, InsDivider } from "../components/dividers"
import { SectionLink } from "../components/links/sectionLink"

const IndexPage = () => (
  <Layout>
    <SEO title="" />
    <HeroSection>
      <H1>We build brands that serve smarter and shapes the future.</H1>
    </HeroSection>
    <FlDivider />
    <LargeSection right="true">
      <H4>What We Do</H4>
      <H2>
        We use strategy, design, and technology to build meaningful experiences
        for customers and lead you to future innovation.
      </H2>
      <SectionLink to="/capabilities">Our capabilities</SectionLink>
    </LargeSection>
    <InsDivider left="true" />
    <LargeSection right="true">
      <H4>Who We Are</H4>
      <H2>
        Concrezan is a brand experience and service design consultancy. We’re
        built different. We dream big for a better future.
      </H2>
      <SectionLink to="/studio">Our studio</SectionLink>
    </LargeSection>
  </Layout>
)

export default IndexPage
